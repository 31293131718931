import React from "react";
import { useState } from "react";
//Snackbars provide brief notifications. The component is also known as a toast.
import { Snackbar } from "@material-ui/core"; 
import Alert from "@material-ui/lab/Alert";
import { useEffect } from "react";
import { LinearProgress } from "@material-ui/core";
import Loader from "../Loader";

//Status Component is used to display messages based on actionstatus prop which is passed to it

function Status(props) {
  //Destructuring props to extract required values
  const { actionstatus, setactionstatus, message } = props;
  //State variable to manage the visibility of Snackbar ->Snackbar is used to show notification messages
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (actionstatus == "success" || actionstatus == "failure") {
      //If the actionstatus is either "success" or "failure," it calls the handleClick()
      handleClick();
    }
  }, [actionstatus]); //runs only when actionstatus changes

  // sets 'open' to true, which will show the Snackbar with the appropriate status message.
  const handleClick = () => {
    setOpen(true);
  };

  //closes the Snackbar when called. It is executed when the Snackbar is closed manually by clicking the close button or when the autoHideDuration expires.
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setactionstatus(""); //set status to ""
    setOpen(false); //closes Snackbar
  };

  // takes the currentstatus as an argument
  const statusgenerator = (currentstatus) => {
    switch (currentstatus) {
      case "invisible":
        return <></>; // renders nothing

      case "loading":
        //returns a Material-UI LinearProgress component to show a loading indicator
        return <LinearProgress color="secondary" />;

      case "success":
        //returns a Material-UI Snackbar with an Alert of "success"
        return (
          //autoHideDuration is set to 3 seconds for automatic dismissal.
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success">
              {/* Show a success message */}
              {message ? message : "Operation was completed successfully!"}
            </Alert>
          </Snackbar>
        );

      case "failure":
        //returns a Material-UI Snackbar with an Alert of "error"
        return (
          //autoHideDuration is set to 3 seconds for automatic dismissal.
          <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="error">
              {/* Show a error message */}
              {message ? message : "Operation failed due to an error"}
            </Alert>
          </Snackbar>
        );
        default:{
          return <></>
        }
    }
  };
  /* Status component returns a <div> that contains the statusgenerator function. The actionstatus 
  prop is passed as an argument to the statusgenerator function, which determines what status 
  message should be displayed.
  */
  return <div style={{ width: "100%" ,display:actionstatus==="loading"||actionstatus==="success"||actionstatus==="falure"?"block":"none" }}>{statusgenerator(actionstatus)}</div>;
}

export default Status;


export function LoaderComponent(){
 
    return (<div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "white",
      }}
    >
      
        <div style={{ width: "100px", height: "100px" }}>
          <Loader color="#000" />
        </div>
     
    </div>)
}
