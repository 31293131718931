import React, { useEffect } from "react";
import { useState } from "react";
import styles from "../Splash.module.css";
import { useFormik } from "formik"; // for handling form state and validation
import * as Yup from "yup"; //Yup for form validation schema,
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  IconButton,
  FormHelperText,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Status from "../components/Status";
import { ArrowBack, CheckCircleOutline } from "@material-ui/icons";
import tabeebLogo from "../TABEEB.png";

//It specifies the validation rules for each form field
const validationSchema = Yup.object({
  firstName: Yup.string().required("Required"),
  lastName: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  clinicName: Yup.string().required("Required"),
  userType: Yup.string().required("Required"),
  message: Yup.string(), //message field is optional
});

//its a Material-UI hook for defining custom styles for components
const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(2),
  },
}));

const RequestAccessNew = ({ setShowLoginScreen, setScreenType }) => {
  //getting clinics
  const [clinics, setClinics] = useState([]);
  useEffect(() => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    fetch(`${process.env.REACT_APP_ENDPOINT}/clinic`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          console.log("Error in getting error");
        }
        return response.json();
      })
      .then((data) => {
        setClinics(data);
      })
      .catch((error) => {
        console.log("error in getting data");
      });
  }, []);
  //console.log("clinics:",clinics)

  const [showConfirmation, setShowConfirmation] = useState(false);

  //sets up the classes constant to use the custom styles defined using 'useStyles'
  const classes = useStyles();

  const [actionstatus, setactionstatus] = useState("invisible");
  const [message, setmessage] = useState("");

  //useFormik hook is used to manage the form state and form submission logic
  const formik = useFormik({
    // initialValues object sets up the initial form values
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      clinicName: "",
      userType: "",
      message: "",
      clinicId: null,
    },
    //yup validation defined earlier
    validationSchema: validationSchema,
    //function that is called when the form is submitted
    onSubmit: (values) => {
      //console.log("access request values", values);

      //sets up the POST request to the server
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(values),
      };
      setactionstatus("loading");
      fetch(`${process.env.REACT_APP_ENDPOINT}/accessrequest`, requestOptions) //This URL is an environment variable that holds the base URL for the API endpoint.
        .then((response) => {
          console.log(response.status);
          /*checks if the HTTP status code of the response is 201, which usually indicates a successful 
      creation of a resource */
          if (response.status == 201) {
            setactionstatus("success");
            setmessage("Your Request Was Submitted!");
            setShowConfirmation(true);
            /*calls the resetForm() function provided by Formik to reset the form to its initial state 
        after successful submission. This clears the form fields.*/
            formik.resetForm();
          } else {
            //If the response status is not 201 (i.e., some error occurred on the server-side), it throws an error.
            throw new Error();
            setmessage("Request Not Submitted!");
          }

          return response.json();
        })
        .then((data) => {
          //console.log("data send to accessrequest", data);
        })
        .catch((error) => {
          setactionstatus("failure");
          setmessage("Something Went Wrong!");
          console.log(error);
        });
    },
  });

  return (
    <div>
      {/*container for the login assistance screen */}
      {/* onSubmit prop is set to formik.handleSubmit, so when the form is submitted, 
        the formik.handleSubmit function will be called to handle the submission. */}
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.formContainer}>
          {/* renders Status component and passes actionstatus, setstatus, and message props to it */}
          <Status
            actionstatus={actionstatus} //used to pass the current status,
            setactionstatus={setactionstatus} // used to update the status when needed
            message={message} //used to pass the status message
          />
          <img
            style={{ width: "130px", height: "45px" }}
            src={tabeebLogo}
            alt="tabeel_logo"
          />
          <div className={styles.heading}>
            <IconButton aria-label="delete">
              <ArrowBack
                onClick={() => {
                  setShowLoginScreen(true);
                  setScreenType("login");
                }}
              />
            </IconButton>
            <Typography style={{ fontWeight: "500" }} variant="h6">
              Request Access
            </Typography>
          </div>
          {showConfirmation ? (
            <AcessRequestSuccess />
          ) : (
            <>
              {/*form includes various input fields, such as <TextField> and <Select>, for collecting user data */}
              <div
                style={{ display: "flex", alignItems: "center", gap: "15px" }}
              >
                <TextField
                  //name prop specifies the name of the input field
                  name="firstName" //used as the key to identify this input field in the formik form state.
                  label="First Name" //displayed above the text field.
                  value={formik.values.firstName} //value prop sets the current value of the input field
                  /*onChange prop specifies the event handler to be executed when the user types or changes
             the input value, in this case, it is connected to formik.handleChange, which 
             will handles the change and updates the formik form state accordingly.*/
                  onChange={formik.handleChange}
                  /* error check for error in input field.If the field has been touched and there's an
             error, the error prop will be true*/
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  //helperText will display the corresponding error message when input field has an error.
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  fullWidth
                  variant="outlined"
                  size="small"
                />
                <TextField
                  name="lastName"
                  label="Last Name"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  fullWidth
                  variant="outlined"
                  size="small"
                />
              </div>
              <div>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="user-type-label">Role</InputLabel>
                  <Select
                    name="userType"
                    labelId="user-type-label"
                    value={formik.values.userType}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.userType && Boolean(formik.errors.userType)
                    }
                    label="Role"
                  >
                    <MenuItem value="doctor">Doctor</MenuItem>
                    <MenuItem value="nurse">Nurse</MenuItem>
                    <MenuItem value="front">Front Desk Staff</MenuItem>
                    <MenuItem value="clinic">Clinic Manager</MenuItem>
                    <MenuItem value="patient">Patient</MenuItem>
                  </Select>
                  <FormHelperText>
                    {formik.touched.userType && formik.errors.userType && (
                      <div style={{ color: "#FF184A" }}>
                        {formik.errors.userType}
                      </div>
                    )}
                  </FormHelperText>
                </FormControl>
              </div>
              <div>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="user-type-label">Clinic</InputLabel>
                  <Select
                    name="clinicName"
                    labelId="clinic-name-label"
                    value={formik.values.clinicName}
                    //onChange={formik.handleChange}
                    onChange={(event) => {
                      formik.handleChange(event);
                      const selectedClinic = clinics.find(
                        (c) => c.name === event.target.value
                      );
                      formik.setValues({
                        ...formik.values,
                        clinicName: event.target.value,
                        clinicId: selectedClinic ? selectedClinic.id : null,
                      });
                    }}
                    error={
                      formik.touched.clinicName &&
                      Boolean(formik.errors.clinicName)
                    }
                    label="Clinic"
                  >
                    {clinics &&
                      clinics.length > 0 &&
                      clinics.map((clinic) => (
                        <MenuItem key={clinic.id} value={clinic.name}>
                          {clinic.name}
                        </MenuItem>
                      ))}
                  </Select>
                  <FormHelperText>
                    {formik.touched.clinicName && formik.errors.clinicName && (
                      <div style={{ color: "#FF184A" }}>
                        {formik.errors.clinicName}
                      </div>
                    )}
                  </FormHelperText>
                </FormControl>
              </div>
              <div>
                <TextField
                  variant="outlined"
                  size="small"
                  name="email"
                  label="Email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  fullWidth
                />
              </div>
              <div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  id="message"
                  name="message"
                  label="Message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.message && Boolean(formik.errors.message)
                  }
                  helperText={formik.touched.message && formik.errors.message}
                />
              </div>
              <div>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                >
                  REQUEST ACCESS
                </Button>
              </div>
            </>
          )}
          {/**** */}
        </div>
      </form>
    </div>
  );
};

function AcessRequestSuccess() {
  return (
    <div className={styles.container}>
      <CheckCircleOutline style={{ color: "#2E7D32" }} fontSize="large" />
      <div className={styles.text}>
        <p style={{ fontWeight: "500" }}>Request Submitted</p>
        <p>Please check your email for further instructions</p>
      </div>
    </div>
  );
}

export default RequestAccessNew;
