import React from 'react'
import styles from './CarousalCard.module.css'

function CarousalCard({ svgImage, title, description}) {
  return (
    <div className={styles.mainContainer}>
        <div className={styles.imageContainer}>
            <img src={svgImage} alt="svgImage" className={styles.image}/>
        </div>
        <div className={styles.textContainer}>
           <div className={styles.title}>{title}</div>
           <div className={styles.description}>{description}</div>
        </div>
    </div>
  )
}

export default CarousalCard