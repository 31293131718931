import React, { useState, useEffect } from 'react';
import SwipeableViews from 'react-swipeable-views';
import styles from "./CarousalComponent.module.css"; // Import styles from the CSS module

const CarouselComponent = ({ slides }) => {
  const [index, setIndex] = useState(0);
  const autoPlayInterval = 3000; // Duration for each slide in milliseconds

  const handleChangeIndex = (index) => {
    setIndex(index);
  };

  const goToPrevSlide = () => {
    const prevIndex = index > 0 ? index - 1 : slides.length - 1;
    setIndex(prevIndex);
  };

  const goToNextSlide = () => {
    const nextIndex = (index + 1) % slides.length;
    setIndex(nextIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      goToNextSlide(); // Change to the next slide automatically
    }, autoPlayInterval);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [index]);

  return (
    <div className={styles.carouselContainer}>
      <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
        {slides}
      </SwipeableViews>
      <div className={styles.controls}>
       
        <div className={styles.indicators}>
          {slides.map((_, i) => (
            <div
              key={i}
              className={`${styles.indicator} ${i === index ? styles.active : ''}`}
              onClick={() => setIndex(i)}
            />
          ))}
        </div>
       
      </div>
      <div className={`${styles.arrow} ${styles.arrowLeft}`} onClick={goToPrevSlide}>{"<"}</div>
      <div className={`${styles.arrow} ${styles.arrowRight}`} onClick={goToNextSlide}>{">"}</div>
    </div>
  );
};

export default CarouselComponent;