//utility class for time related functions

import { toLower } from "lodash";

export default class StringUtils {
    //capitalize first letter of all words in a string
  static capitalize = (str) => {
    return str
      .split(" ")
      .map((word) => word[0].toUpperCase() + word.slice(1))
      .join(" ");
  }

  //check if string conatins substring
  static contains = (str, subStr) => {
    return str.toLowerCase().indexOf(subStr.toLowerCase()) !== -1;
  }
}
