import React, { createContext, useEffect, useState } from 'react';

export const EnvironmentContext = createContext();

export const EnvironmentProvider = ({ children }) => {
    
    const [isTestEnvironment, setIsTestEnvironment] = useState(false);
  
    useEffect(() => {
      // In a real application, you might fetch this from an API or environment variable
      const checkEnvironment = async () => {
        // Simulating an API call or environment check
        const env = process.env.REACT_APP_ENVIRONMENT || 'production';
        setIsTestEnvironment(env === 'test');
      };
  
      checkEnvironment();
    }, []);
  
    return (
      <EnvironmentContext.Provider value={{ isTestEnvironment }}>
        {children}
      </EnvironmentContext.Provider>
    );
  };