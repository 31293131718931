import "./App.css";
import { useEffect, useState, lazy, Suspense, useRef } from "react";
import Loader from "./Loader";

//import Nurse from './nursescreen/Nurse'
import Login from "./loginscreen/Login";
//import Doctor from './doctorscreen/Doctor'
//import Pdh from './pdhscreen/Pdh'
import {
  HubConnectionBuilder,
  LogLevel,
  HttpTransportType,
  HubConnectionState
} from "@microsoft/signalr";

import { EnvironmentProvider } from "./utils/EnvironmentProvider";

import { BrowserRouter as Router, Routes, Route,Navigate } from 'react-router-dom';
import StringUtils from "./utils/StringUtils";

//import Front from './frontscreen/Front';
//import Admin from './adminscreen/Admin';
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
const Nurse = lazy(() => import("./nursescreen/Nurse"));
//const Login = lazy(() => import('./loginscreen/Login'));
const Doctor = lazy(() => import("./doctorscreen/Doctor"));
const Pdh = lazy(() => import("./pdhscreen/Pdh"));
const Front = lazy(() => import("./frontscreen/Front"));
const Admin = lazy(() => import("./adminscreen/Admin"));
const SuperAdmin = lazy(() => import("./superadminscreen/SuperAdmin"));
const ClinicSelect = lazy(()=> import("./ClinicSelectScreen/ClinicSelectScreen")) 

//renderLoader component calls Loader component
// It renders a loader component used during the lazy loading process.
const renderLoader = () => (
  <div
    style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ width: "100px", height: "100px" }}>
        <Loader color="#000" />
      </div>
    </div>
  </div>
);

function App() {
  //The appstate represents the current screen , It renders different screens based on current value of appstate
  const [appstate, setappstate] = useState("initial");
  //token: It stores a user authentication token.
  const [token, settoken] = useState("");
  //credentialid: It stores the user credential Id.
  const [credentialid, setcredentialid] = useState("");
  // It stores the SignalR Connection
  const [hubconnection, sethubconnection] = useState(null);

  const [connectionState, setconnectionState] = useState('disconnected');
  //When using the above state variable Appstate, it gets set Asynchronously but we need a variable which
  //gets set instantly or synchronously so that it can be used as a flag to not restart the hubconnection
  //once we logout.

  var state = "initial"; //state has same value as initial appstate
  const appStateRef = useRef(appstate);
  const theme = createTheme({
    palette: {
      primary: {
        main: "#1976D2", // Change this to your desired primary color
      },
      amber: {
        main: "#ED6C02", // Change this to your desired primary color
      },
    },
  });
  //console.log("App.jsx rendered")
  //console.log("appstate", appstate);
  
 //creates the hub connection using the previously configured options.

    //check url and based on that set appstate
   
useEffect(() => {
  const url = window.location.href;
//check if url is not empty ie not ./
//if(url!=="http://localhost:3000/"){}


  if(localStorage.getItem("token")){
//if token is present means logged in.


  settoken(localStorage.getItem("token"));

// Next we will check url and based on that set appstate
  if (url.includes("doctor")) {
    setappstate("doctor");
  } else if (url.includes("nurse")) {
    setappstate("nurse");
  } else if (url.includes("pdh")) {
    setappstate("pdh");
  } else if (url.includes("front_desk")) {
    setappstate("front_desk");
  }
  else if (url.includes("admin")) {
    setappstate("admin");
  }
  else if (url.includes("super_admin")) {
    setappstate("super_admin");
  }
  else {
    //if url is empty then check usergroup and set appstate
    //console.log("empty url and logged in");
    if(localStorage.getItem("usergroup")){
    setappstate(localStorage.getItem("usergroup"));  
  }
  }
}
}, []);


useEffect(() => {
  //console.log("entered useeffect for initializing connection inside app.js");
  if(!hubconnection&&token){
    //console.log("entered useeffect for initializing connection and now starting initialization in app.js");
    //new hub-connection is created when 'appstate' and 'token' changes
 const connection = new HubConnectionBuilder()
 .withUrl(`${process.env.REACT_APP_HUB_ENDPOINT}/mainHub`, {
   accessTokenFactory: () => token,
   skipNegotiation: true,
   transport: HttpTransportType.WebSockets})
 // Q? Didn't get this below line properly ?
 .configureLogging(LogLevel.Trace) //connection will log information messages
 .build(); 

      sethubconnection(connection)
  }
}, [token]);

useEffect(() => {
  //cleanup function whwn component unmounts
  return () => {
    //console.log("entered cleanup function");
    if (hubconnection) {
      //console.log("entered cleanup function and now stopping connection in app.js");
      hubconnection.stop();
    }
  };
}, []);

 
  //useEffect is used to manage hub connection
  useEffect(() => {
 
 
    //console.log("entered useeffect for starting connection inside app.js");
    appStateRef.current = appstate;
    ////console.log("appstate in useEffect", appstate);

    //appstate == "initial" && token == "" is by default
    //condition inside if statement will run if appstate and token changes
    if (appstate !== "initial"&& hubconnection) {
      //start() async function is used start the connection and handles errors.
      //console.log("entered useeffect for starting connection and now starting connection in app.js");
      async function start() {
        //console.log("entered start function");
        try {
          setconnectionState('connecting')
          if(hubconnection.state===HubConnectionState.Connected||hubconnection.state===HubConnectionState.Connecting){
            //console.log("hubconnection already connected in start function")
            setconnectionState(hubconnection.state===HubConnectionState.Connected?'connected':'connecting')
            return;
          }
          await hubconnection.start();
          //console.log("SignalR Connected.");
          //sethubconnection(connection);
          setconnectionState('connected')
          localStorage.setItem("connectionStatus", 1);
        } catch (err) {
          console.log(err);
          //start function runs again after 5s when error accurs

          setTimeout(start, 5000);
        }
      }
     
     
      //this callback function is used when hub connection is closed
      hubconnection.onclose(async (error) => {
        //Do not restart the hubconnection if logged out.
        /*
        it checks weather state variable is not equal to 'initial',
        if true->means user is logged in, and it restarts the connection using start() ,
        if false->means user is logged out then don't restart connection
        */
       //console.log("onclose callbck being called")
        //console.log("connection closed");
        setconnectionState('disconnected')
        localStorage.setItem("connectionStatus", 0);
        if (appStateRef.current !== "initial") {
          //need to give timeout for server to end previous connection before starting new one
          if(hubconnection.state===HubConnectionState.Connected||hubconnection.state===HubConnectionState.Connecting){
            //console.log("hubconnection already connected or connecting in onclose")
            //setconnectionState('connected')
            return;
          }
          setTimeout(start, 5000);
          return;
        }
       
      });

      /*callback function to be executed when the hub receives a message with the event name 
      "ReceiveMessag" */
      hubconnection.on("ReceiveNotification", (user, message) => {
        //user and message, which represent the data received from the hub.
        //console.log("Called from Server " + message);
      });

      hubconnection.on("ReceiveMessage", (user, message) => {
        ////console.log("broadcasting is ", broadcasting)
        if(StringUtils.contains(message,"TIMEUPDATE")){
          let arr = message.split("_");
          let time = arr[1];
          ////console.log("setting my time",time);
          localStorage.setItem('currentTime', parseInt(time));
         // setCurrentTime(parseInt(time));
        }       
      });
      if(hubconnection.state===HubConnectionState.Disconnected){
        // Start the connection.
        //console.log("starting signalr fron appjsx",hubconnection.state)
        if(hubconnection.state===HubConnectionState.Connected||hubconnection.state===HubConnectionState.Connecting){
          //console.log("hubconnection already connected or connecting in main useeffect")
          return;
        }

        start();
      }
      
    }

    
  }, [appstate,hubconnection]); //useEffect runs only when appstate change

 

  const apphandler = (input) => {
    state = input; //state value contains same value as of appstate
    setappstate(input);
  };
  //tokenhandler sets the token
  const tokenhandler = (input) => {
    settoken(input);
  };
  //credentialidhandler sets the credentialid
  const credentialidhandler = (input) => {
    setcredentialid(input);
  };

 

 

 

  /*
  apphandler, tokenhandler, credentialidhandler are Handler Functions and these functions are 
  used to handle state changes.
  The handler functions are not directly called within code. Instead, these handler functions are 
  passed down as props to child components where they can be called and used.

  apphandler: It is passed as a prop to the Login, Doctor, Nurse, Pdh, Front, Admin, and SuperAdmin 
    components. These components can call this function and change appstate 

  tokenhandler: Similar to apphandler, it is passed as a prop to the different components. 
      When the user logs in and the authentication token is received, 
      the components can call this function to set the token in the App component.

  credentialidhandler: Like the other handlers, it is passed as a prop to the different components. 
        When the user provides their credentials, the components can call this function to 
        set the credentialid in the App component.
  
  */


////console.log("usergroup is",localStorage.getItem("usergroup"));

  return (
    <EnvironmentProvider>
    <ThemeProvider theme={theme}>
      <div className="App">
        <Routes>
          <Route path="/"  element={  
          localStorage.getItem('usergroup')?<Navigate to={`/${localStorage.getItem('usergroup')}`}/>:<Suspense fallback={renderLoader()}>
            {<Login
              apphandler={apphandler} //the login component will receive the function that is stored in apphandler
              tokenhandler={tokenhandler}
              credentialidhandler={credentialidhandler}
            />}
          </Suspense>} />
          <Route path="/doctor/*" element={
            (localStorage.getItem("usergroup")==="Doctor") ? 
          <Suspense fallback={renderLoader()}>
            <Doctor
              apphandler={apphandler}
              token={token}
              credentialid={credentialid}
              hubconnection={hubconnection}
              connectionState={connectionState}
              sethubconnection={sethubconnection}
            />
          </Suspense>:<Navigate to="/" replace />} />

          <Route path="/nurse/*" element={ 
             (localStorage.getItem("usergroup")==="Nurse") ? 
          <Suspense fallback={renderLoader()}>
            <Nurse
              apphandler={apphandler}
              token={token}
              credentialid={credentialid}
              hubconnection={hubconnection}
              connectionState={connectionState}
              sethubconnection={sethubconnection}
            />
          </Suspense>:<Navigate to="/" replace />} />
          <Route path="/pdh/*" element={
             (localStorage.getItem("usergroup")==="PDH") ? 
          <Suspense fallback={renderLoader()}>
            <Pdh
              apphandler={apphandler}
              token={token}
              credentialid={credentialid}
              hubconnection={hubconnection}
              connectionState={connectionState}
              sethubconnection={sethubconnection}
            />
          </Suspense>:<Navigate to="/" replace />} />
          <Route path="/front_desk/*" element={
             (localStorage.getItem("usergroup")==="Front_Desk") ? 
          <Suspense fallback={renderLoader()}>
            <Front
              apphandler={apphandler}
              token={token}
              credentialid={credentialid}
              hubconnection={hubconnection}
              connectionState={connectionState}
              sethubconnection={sethubconnection}
            />
          </Suspense>:<Navigate to="/" replace />} />
          <Route path="/admin/*" element={
             (localStorage.getItem("usergroup")==="Admin") ? 
          <Suspense fallback={renderLoader()}>
            <Admin
              apphandler={apphandler}
              token={token}
              credentialid={credentialid}
              hubconnection={hubconnection}
              connectionState={connectionState}
              sethubconnection={sethubconnection}
            />
          </Suspense>:<Navigate to="/" replace />} />
          <Route path="/super_admin/*" element={
             (localStorage.getItem("usergroup")==="Super_Admin") ? 
          <Suspense fallback={renderLoader()}>
            <SuperAdmin
              apphandler={apphandler}
              token={token}
              credentialid={credentialid}
              hubconnection={hubconnection}
              connectionState={connectionState}
              sethubconnection={sethubconnection}
            />
          </Suspense>:<Navigate to="/" replace />} />
          <Route path="/clinic_select/*" element={    
          <Suspense fallback={renderLoader()}>
            <ClinicSelect/>
          </Suspense>}/>
        </Routes>
     
      </div>
      {/*calling renderelement and passing appstate value to it */}
    </ThemeProvider>
    </EnvironmentProvider>
    /*
      ThemeProvider: The entire app is wrapped inside ThemeProvider to provide the Material-UI
       theme to all components.
    */
  );
}

export default App;
